<template>
  <v-app-bar app clipped-left>
    <v-img :src="logoSrc" alt="logo" max-height="40" max-width="40" />
    <v-toolbar-title class="ml-3">ember CHECKERS Admin panel</v-toolbar-title>
	<!--  
    <v-btn small class="pa-0 ml-2 elevation-0" :href="linkToReleaseNotes">{{
      version
    }}</v-btn>-->

    <v-spacer />

    <div class="d-flex">
      <div>
        <div>
          {{ $t("connection.serverUrl") }}{{ $t("separator")
          }}<code v-if="serverUrl">{{ serverUrl }}</code>
        </div>
        <div>
          {{ $t("status") }}{{ $t("separator")
          }}<ConnectionStatus :connected="connected" />
        </div>
      </div>

      <v-btn @click="onUpdate" class="ml-3 align-self-center">{{
        $t("update")
      }}</v-btn>
    </div>
  </v-app-bar>
</template>

<script>
import { mapState } from "vuex";
import ConnectionStatus from "./ConnectionStatus";

const version = process.env.VERSION;

export default {
  name: "AppBar",

  components: { ConnectionStatus },

  data() {
    return {
      version,
    };
  },

  computed: {
    ...mapState({
      logoSrc: (state) =>
        state.config.darkTheme
          ? require("../assets/logo1.png")
          : require("../assets/logo1.png"),
      serverUrl: (state) => state.connection.serverUrl,
      connected: (state) => state.connection.connected,
    }),
    linkToReleaseNotes() {
      return (
        "https://github.com/socketio/socket.io-admin-ui/releases/tag/" + version
      );
    },
  },

  methods: {
    onUpdate() {
      this.$emit("update");
    },
  },
};
</script>
